import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "testing-requirements"
    }}>{`Testing requirements`}</h1>
    <p>{`... or, what kind of regression tests should City of Helsinki services
have?`}</p>
    <blockquote>
      <p parentName="blockquote">{`well-tested program -
adapting to every change -
no surprises here!
-- Panu Kalliokoski, programming haikus`}</p>
    </blockquote>
    <h2 {...{
      "id": "tests-that-should-be-written"
    }}>{`Tests that should be written`}</h2>
    <ul>
      <li parentName="ul">{`integration tests that cover the happy paths of each feature and user
story`}<ul parentName="li">
          <li parentName="ul">{`this means a program that does what the user would do (against a
real service) and checks that what happens is what should happen`}</li>
        </ul></li>
      <li parentName="ul">{`integration or unit tests that cover forbidden behavior`}<ul parentName="li">
          <li parentName="ul">{`a program that does what a hacker would do and checks that it fails`}</li>
        </ul></li>
      <li parentName="ul">{`unit tests for every method/function whose logic is "complicated"`}<ul parentName="li">
          <li parentName="ul">{`"complicated" means there are at least three execution paths in the
method/function`}</li>
        </ul></li>
      <li parentName="ul">{`integration or unit tests that provide full code coverage at least for
all code that implements the running service`}<ul parentName="li">
          <li parentName="ul">{`this is meant to exclude build-time helper scripts and similar
stuff.  But consider testing them, too!`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "tests-that-would-be-nice"
    }}>{`Tests that would be nice`}</h2>
    <ul>
      <li parentName="ul">{`at least rudimentary performance tests: how long does it take to load
each view 1000 times?`}<ul parentName="li">
          <li parentName="ul">{`these produce an important time series where you can find when you
made a performance blunder`}</li>
        </ul></li>
      <li parentName="ul">{`randomised fuzz testing to see that the service doesn't break (or
reports errors correctly)`}</li>
      <li parentName="ul">{`state invariants if your data has interdependencies`}<ul parentName="li">
          <li parentName="ul">{`these can be made on database level`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "how-tests-should-be-written"
    }}>{`How tests should be written`}</h2>
    <ul>
      <li parentName="ul">{`Prefer integration (and e2e) tests over unit tests.`}<ul parentName="li">
          <li parentName="ul">{`Use unit tests for stuff that is hard to understand even on a local
(unit) level.`}</li>
          <li parentName="ul">{`Integration tests are good for covering a lot of stuff quickly.
Unit tests are good for finding exactly where a problem is.  `}</li>
        </ul></li>
      <li parentName="ul">{`Don't use mocks unless it simplifies tests considerably.  That is,
don't redo software components in a simplified way.`}<ul parentName="li">
          <li parentName="ul">{`instead, use the real components for tests, too.`}</li>
          <li parentName="ul">{`if the real component is really hard to use, then a mock (or fake
version) might be feasible.  Consider fixing the real component,
though.`}</li>
        </ul></li>
      <li parentName="ul">{`Don't overtest or undertest.  (This is hard.)`}<ul parentName="li">
          <li parentName="ul">{`Undertesting means that broken things go unnoticed.  Write enough
tests to cover all execution paths and test the results are right
from the unit/service user's point of view.`}</li>
          <li parentName="ul">{`Overtesting means that change is hard because most of the time goes
into fixing tests.  In the test, don't check for details that are
irrelevant and might change.`}</li>
        </ul></li>
      <li parentName="ul">{`Use fixtures (such as prepopulated data in the database) if your tests
need it.`}<ul parentName="li">
          <li parentName="ul">{`However, don't make your tests depend very much on the detail of
testing data.  Testing data will inevitably have to be updated
someday.`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "how-to-write-easily-testable-code"
    }}>{`How to write easily testable code`}</h2>
    <ul>
      <li parentName="ul">{`Build stateless services if you can.  Put state into specialised
stateful services (buckets, databases).`}<ul parentName="li">
          <li parentName="ul">{`This way, the state can always be initialised (by fixtures) so that
tests work predictably.`}</li>
        </ul></li>
      <li parentName="ul">{`Use stateless handlers (pure functions/components) wherever you can.`}<ul parentName="li">
          <li parentName="ul">{`Model your program as functions that process input data into output
data.  Hoist all I/O and other interaction onto as high level in the
program as possible.`}</li>
        </ul></li>
      <li parentName="ul">{`In UI programs, separate state handling from the rest of the program.`}<ul parentName="li">
          <li parentName="ul">{`You can test your reducers (state update functions) separately if
they are "complicated".`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      